import Amplify from '@aws-amplify/core';
import { Auth } from "aws-amplify";
import Storage from '@aws-amplify/storage';

export function configureAmplify() {
  Amplify.configure(
  {
   Auth: {
    mandatorySignIn: true,
    identityPoolId: process.env.REACT_APP_identityPoolId,
    region: process.env.REACT_APP_region,
    userPoolId: process.env.REACT_APP_userPoolId,
    userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,
    userPoolWebClientSecret: process.env.REACT_APP_userPoolWebClientSecret

    },
   
  Storage: { 
    AWSS3:{
      bucket: process.env.REACT_APP_Bucket_name,
      region: process.env.REACT_APP_region,
      identityPoolId: process.env.REACT_APP_identityPoolId
      }
    }
  }
 );
} 

//Configure Storage with S3 bucket information
export async function SetS3Config(bucket, level){
  Storage.configure({ 
         bucket: bucket,
         level: level,
         region: process.env.REACT_APP_region,  
         identityPoolId: process.env.REACT_APP_identityPoolId,
         customPrefix: {
          public: 'public/',
          protected: 'protected/',
          private: 'private/'
      },
      });
}


export function checkAmplifyCredentials(passCallback){
  Auth.currentAuthenticatedUser({
    bypassCache: true
  }).then(function (user){
    passCallback(user);

  }).catch(function(err){
    // If the user is not authenticated reroute to the login page
    window.location.hash = '/login';
  });


}