import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  user:{},
  username: '',
  s3_bucket:'',
  site_details:{},
  selected_site:''
}
 
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'set_state':
        return {...state, ...rest['state'] }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
